import styled from "styled-components";
import { TileX } from "./TileX";
import { Tile, getWordScore, isLetterTile } from "./game";

interface BoardProps {
  words: Tile[][];
  selectTile?: (tile: Tile) => void;
  deselectTile?: (index: number) => void;
  selectedTiles: Tile[];
  arrangement: Tile[] | undefined;
  triedIllegalWord: false | number;
  hideLetters?: boolean;
  sturdyBonus?: boolean;
  allTilesBonus?: boolean;
  showDebugWordScores?: boolean;
  safeMode?: boolean;
}

export const Board: React.FC<BoardProps> = ({ 
  words, 
  selectTile, 
  deselectTile,
  selectedTiles,
  arrangement,
  triedIllegalWord,
  hideLetters,
  sturdyBonus,
  allTilesBonus,
  showDebugWordScores,
  safeMode,
}) => {
  return <BoardDiv>
    {words.map((word, wordIndex) => {
      return <WordDiv key={wordIndex}>
        {word.map((tile, i) => {
          const selectedIndex = selectedTiles.findIndex(selectedTile => selectedTile.id === tile.id);
          const isSelected = selectedIndex !== -1;
          return <TileX 
            tile={hideLetters ? hideLetter(tile) : tile} 
            key={i} 
            onClick={() => {
              if (isSelected) {
                deselectTile && deselectTile(selectedIndex);
              } else {
                selectTile && selectTile(tile);
              }
            }} 
            isSelected={isSelected} 
          />;
        })}
        { showDebugWordScores && <WordScoreWrapper key={wordIndex + 'score'}>
          <WordScore $draft={false}>{getWordScore(word)}</WordScore>
        </WordScoreWrapper>}
      </WordDiv>
    })}
    {arrangement && arrangement?.length > 0 && <WordDiv 
      $illegal={triedIllegalWord !== false ? triedIllegalWord : undefined}
      key={"update" + triedIllegalWord}
    >
      {arrangement.map((tile, i) => {
        return <TileX 
          tile={tile} 
          key={i} 
          onClick={() => deselectTile && deselectTile(i)} 
          isIllegal={tile.id === undefined}
          isDraft={true}
        />;
      })}
    </WordDiv>}
    { safeMode && <BonusDiv><BonusInner>+0</BonusInner></BonusDiv>}
    { sturdyBonus && <BonusDiv><BonusInner>+10</BonusInner></BonusDiv>}
    { allTilesBonus && <BonusDiv><BonusInner>+20</BonusInner></BonusDiv>}
  </BoardDiv>;
}

const WordDiv = styled.div<{$illegal?: number}>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 3px;
  transform: translate3d(0, 0, 0);
  ${({$illegal}) => $illegal ? `
    animation: shake 0.75s cubic-bezier(.36,.07,.19,.97) both;
  ` : ''}
  @keyframes shake {
    10%, 90% {
      transform: translate3d(-.4px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(.8px, 0, 0);
    }

    30%, 50%, 70% {
      transform: translate3d(-1.6px, 0, 0);
    }

    40%, 60% {
      transform: translate3d(1.6px, 0, 0);
    }
  }
`;

const WordScoreWrapper = styled.div`
  position: relative;
`

const WordScore = styled.div<{ $draft: boolean }>`
  max-width: fit-content;
  font-size: 7px;
  background-color: #d3d9fc;
  border: 1px solid #a3aad2;
  box-shadow: -1px 1px #a3aad2;
  padding: 2px 3px 1px 3px;
  border-radius: 4px;
  ${({$draft}) => $draft ? 'opacity: 0.7;' : ''}
`

const BoardDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  row-gap: 8px;
  flex-grow: 1;
  align-content: start;
`;

function hideLetter(tile: Tile) {
  if (isLetterTile(tile)) {
    return {...tile, letter: " "};
  }
  return tile;
}

const BonusDiv = styled.div`
  height: 15px;
  padding: 5px;
  display: inline-block;
  max-width: fit-content;
  border-radius: 5px;
  background-color: #d3d9fc;
  border: 1px solid #a3aad2;
  box-shadow: -1px 1px #a3aad2;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
`;

const BonusInner = styled.div`
  font-size: 16px;
  font-family: "Courier New";
  user-select: none;
  color: #4e493d;
`;